import React from 'react';
import { Link } from 'react-router-dom';
import './products.css';
import dragonCourt from '../../images/dragonCourt.jpg';
import najCourt from '../../images/najcourtland1.jpg';
import najIndustrial from '../../images/Naj-Industrial-layout.gif'
import { IoLocationSharp } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { TbCurrencyNaira } from "react-icons/tb";
import Najcourt1 from '../../pages/estates/najcourts/Najcourt1';

function Products() {
  return (
    <div className="product__wrapper">
        <div className="product__content">
            {/**products title starts here */}
            <div className="section__title">
                <h2>Featured <span>Estates</span></h2>
                <div className="underline"></div>
            </div>
            <div className="productItems">
                {/**Dragon court estate starts here */}
                <div className="productItem">
                    <img src={dragonCourt} className="estatebanner" alt="Dragon Court Estate" />
                    <div className="productItemBottom">
                        <h3 className="estate__name">Dragon Courts Estate</h3>
                        <div className="estateLocation">
                            <IoLocationSharp className='estateIcon' />
                            <p className="estateAddress">
                                Odo Iragushi, Behind Yaba College of Technology Epe Lagos.
                            </p>
                        </div>
                        <div className="estateDocument">
                            <IoDocumentText className='estateIcon'/>
                            Survey | Deed of Assignment
                        </div>
                        <div className="estatePrice">
                            <div className="price">
                                <TbCurrencyNaira className='currencyIcon' />
                                <h2>4,500,000</h2>
                            </div>
                            <div className="plotSize">
                                450 Square Meters
                            </div>
                        </div>
                        <div className="estatePrice">
                            <div className="price">
                                <TbCurrencyNaira className='currencyIcon' />
                                <h2>3,000,000</h2>
                            </div>
                            <div className="plotSize">
                                300 Square Meters
                            </div>
                        </div>
                        <div className="product-btn">
                            <Link to='/estates/dragoncourt' className="prodBtn">See more information</Link>
                        </div>
                    </div>
                </div>
                {/**Dragon Court estates ends here */}
                {/**NAJ court estate scheme 1 starts here */}
                <div className="productItem">
                    <img src={najCourt} className="estatebanner" alt="NAJ Court Estate" />
                    <div className="productItemBottom">
                        <h3 className="estate__name">NAJ Court Estate Scheme 1</h3>
                        <div className="estateLocation">
                            <IoLocationSharp className='estateIcon' />
                            <p className="estateAddress">
                                Opposite T-Junction Epe Lagos.
                            </p>
                        </div>
                        <div className="estateDocument">
                            <IoDocumentText className='estateIcon'/>
                            Registered Survey | Deed of Assignment
                        </div>
                        <div className="estatePrice">
                            <div className="price">
                                <TbCurrencyNaira className='currencyIcon' />
                                <h2>7,000,000</h2>
                            </div>
                            <div className="plotSize">
                                400 Square Meters
                            </div>
                        </div>
                        <div className="product-btn">
                            <Link to='/estates/Najcourt1' className="prodBtn">See more information</Link>
                        </div>
                    </div>
                </div>
                {/**NAJ Court estates scheme 1 ends here */}
                {/**NAJ Industrial Layout starts here */}
                <div className="productItem">
                    <img src={najIndustrial} className="estatebanner" alt="NAJ Industrial Layout" />
                    <div className="productItemBottom">
                        <h3 className="estate__name">NAJ Industrial Layout</h3>
                        <div className="estateLocation">
                            <IoLocationSharp className='estateIcon' />
                            <p className="estateAddress">
                                Ijebu Iwasi Immediately After Toll Gate.
                            </p>
                        </div>
                        <div className="estateDocument">
                            <IoDocumentText className='estateIcon'/>
                            Registered Survey | Deed of Assignment
                        </div>
                        <div className="estatePrice">
                            <div className="price">
                                <TbCurrencyNaira className='currencyIcon' />
                                <h2>10,000,000</h2>
                            </div>
                            <div className="plotSize">
                                1000 Square Meters
                            </div>
                        </div>
                        <div className="product-btn">
                            <a href="#" className="prodBtn">See more information</a>
                        </div>
                    </div>
                </div>
                {/**NAJ Industrial Layout ends here */}
            </div>
        </div>
    </div>
  )
}

export default Products