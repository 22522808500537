import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import './app.css';
import Header from './components/Header/Header';
import Home from './pages/home/Home';
import Footer from './components/footer/Footer';
import Najcourt1 from './pages/estates/najcourts/Najcourt1';
import Dragoncourt from './pages/estates/dragoncourts/Dragoncourt';
import Inspection from './pages/inspection/Inspection';

export default function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/estates/Najcourt1" element={<Najcourt1/>} />
        <Route path="/estates/Dragoncourt" element={<Dragoncourt/>} />
        <Route path="/inspection/Inspection" element={<Inspection/>} />
      </Routes>
      <Footer />
    </Router>
  );
}
