export const links = [
    {
        name: "Home",
        path: "/"
    },

    {
        name: "About us",
        path: "/about"
    },

    {
        name: "Our Estates",
        path: "/estates"
    },
    {
        name: "contact",
        path: "/contact"
    },
    {
        name: "Book Inspection",
        path: "/inspection/inspection"
    }
]