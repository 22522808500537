import React from 'react';
import { useState } from 'react';
import './inspection.css';
import emailjs from 'emailjs-com';

function Inspection() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    date: '',
    message: '',
  });

  const [submissionStatus, setSubmissionStatus] = useState(null);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Add your email service details (service ID, template ID, and user ID)
    const serviceId = 'service_fwvi05a';
    const templateId = 'template_ykcuyrr';
    const userId = 'Dci8WZ32X7GELcO8-';

    // Pass form data to EmailJS, including subject
    const emailData = {
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      date: formData.date,
      message: formData.message,
      subject: `New Inspection Inquiry - ${formData.name}`,
    };

    const formDataWithSubject = new FormData();
    formDataWithSubject.append('name', formData.name);
    formDataWithSubject.append('phone', formData.phone);
    formDataWithSubject.append('email', formData.email);
    formDataWithSubject.append('date', formData.date);
    formDataWithSubject.append('message', formData.message);
    formDataWithSubject.append('subject', `New Inspection Inquiry - ${formData.name}`);

    emailjs
      .send(serviceId, templateId, emailData, userId)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setSubmissionStatus('success');
        // Add any success handling here
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setSubmissionStatus('error');
        // Add error handling here
    });
  };

  return (
    <main className="main">
      <div className="main__section">
        <div className="section__wrapper">
          <div className="inspection-section__title">
            <h2>Book Inspection</h2>
            <p>
              Inspection takes place every workday <br /> 10:00am to 4:00pm.
              Saturdays 10:00am to 3:00pm.
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">Full Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <label htmlFor="phone">Phone Number:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label htmlFor="date">Date of Visit:</label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
            />

            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              required
            />

            <button type="submit">Submit</button>
          </form>
          {submissionStatus === 'success' && (
            <p className='email__success' style={{ color: 'green', textAlign: 'center' }}>Inquiry submitted successfully!</p>
          )}
          {submissionStatus === 'error' && (
            <p style={{ color: 'red' }}>Error submitting inquiry. Please try again.</p>
          )}
        </div>
      </div>
    </main>
  );
}

export default Inspection;
