import React from 'react'
import { useState } from 'react';
import './najcourt1.css'
import { BiSolidLandscape } from "react-icons/bi";
import { TbCurrencyNaira } from "react-icons/tb";
import najcourtland from '../../../images/najcourtland1.jpg'
import { BsFillHousesFill } from "react-icons/bs";
import { IoLocation } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { FaCity } from "react-icons/fa";
import { GiStreetLight } from "react-icons/gi";
import { MdFence } from "react-icons/md";
import { FaLightbulb } from "react-icons/fa";
import { FaRoad } from "react-icons/fa";
import { BiSolidCctv } from "react-icons/bi";
import { PiTreeEvergreenFill } from "react-icons/pi";
import { GiGate } from "react-icons/gi";
import devpic1 from '../../../images/naj1Pics1.jpg'
import devpic2 from '../../../images/naj1Pics2.jpg'
import devpic3 from '../../../images/naj1Pics3.jpg'
import devpic4 from '../../../images/naj1Pics4.jpg'
import faq from '../../../images/Najcourt1landFAQ.pdf'

import najBanner from '../../../images/NajCourtBanner.jpg'

function Najcourt1() {
    const [pdfUrl, setPdfUrl] = useState(null);
    const handleDownload = async (filePath) => {
        try {
          const response = await fetch(filePath);
    
          if (!response.ok) {
            throw new Error(`Failed to fetch file (${response.status} ${response.statusText})`);
          }
    
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
    
          const a = document.createElement('a');
          a.href = url;
    
          // Extract the filename from the path and set it for download
          const filename = filePath.split('/').pop();
          a.download = filename;
    
          a.click();
    
          // Cleanup: Revoke the URL to release resources
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      };   
  return (
    <main className='sectionContainer'>
        <div className="mainwrapper">
            <section className="najHeroSection">
                <img src={najBanner} alt="naj banner" className="najBanner" />
                <div className="aboutnaj">
                    <h2>NAJ COURT ESTATE</h2>
                    <p>Experience Eco-friendly Living with serviced plots</p>
                </div>
            </section>

            <section className="aboutNajmain">
                <div className="aboutnajContent">
                    <div className="about">
                        <div className="aboutSubtitle white">
                            Experience Eco-friendly Living
                        </div>
                        <p>
                            Say yes to a residence of security, comfort, and luxury! 
                            Welcome to NAJ Court, where your dreams of owning the perfect 
                            home come true. Nestled in the heart of Epe, Lagos Nigeria. 
                            at NAJ Court, we believe in offering more than just a place to live;
                            we provide a lifestyle of elegance and convenience our offerings includes Serviced plots, 
                            2-Bedrooms Apartments and 3 bedroom apartments.
                        </p>
                        <p>
                            NAJ Court presents the ideal opportunity to invest in real estate. 
                            Whether you're looking for your dream home, a secure investment, or both, 
                            our estate provides the perfect environment for growth and prosperity.
                        </p>
                        <div className="location">
                            <IoLocation  className='localicon'/>
                            <p><span>Location: </span>Opposite Epe T-Junction</p>
                        </div>
                        <div className="document">
                            <IoIosDocument className='docuicon'/>
                            <p><span>Title Document: </span>Registered Survey and Deed</p>
                        </div>
                        <div className="neighborhood">
                            <FaCity className='neighIcon'/>
                            <p> <span>Neighborhood: </span> St. Augustine University, simi Lagos, Alaro City, International Airport, Epe resort Yaba  Technology institution</p>
                        </div>
                    </div>
                    <div className="aboutOffering">
                        <div className="aboutSubtitle">
                                Available Offerings
                        </div>
                        <div className="offering">
                            <div className="offer">
                                <div className="icon">
                                    <BiSolidLandscape className='landicon' />
                                </div>
                                <div className="offerTitle">
                                   <div className="land">
                                        300sqm Plots
                                   </div>
                                   <div className="price">
                                    <TbCurrencyNaira className='naira'/> 4,500,000
                                   </div>
                                </div>
                            </div>
                            <div className="offer">
                                <div className="icon">
                                    <BiSolidLandscape className='landicon' />
                                </div>
                                <div className="offerTitle">
                                   <div className="land">
                                        500sqm Plots
                                   </div>
                                   <div className="price">
                                    <TbCurrencyNaira className='naira'/> 7,500,000
                                   </div>
                                </div>
                            </div>
                            <div className="offer">
                                <div className="icon">
                                    <BsFillHousesFill className='landicon' />
                                </div>
                                <div className="offerTitle">
                                   <div className="land">
                                        2 Bedrooms Apartment
                                   </div>
                                   <div className="price">
                                    <TbCurrencyNaira className='naira'/> 25,000,000
                                   </div>
                                   <div className="deliverydeposit">
                                    <small>Delivery: Finished</small>
                                    <h5>Initial Deposit: N5,000,000</h5>
                                   </div>
                                </div>
                            </div>
                            <div className="offer">
                                <div className="icon">
                                    <BsFillHousesFill className='landicon' />
                                </div>
                                <div className="offerTitle">
                                   <div className="land">
                                        2 Bedrooms Apartment
                                   </div>
                                   <div className="price">
                                    <TbCurrencyNaira className='naira'/> 17,000,000
                                   </div>
                                   <div className="deliverydeposit">
                                    <small>Delivery: Carcass</small>
                                    <h5>Initial Deposit: N5,000,000</h5>
                                   </div>
                                </div>
                            </div>
                            <div className="offer">
                                <div className="icon">
                                    <BsFillHousesFill className='landicon' />
                                </div>
                                <div className="offerTitle">
                                   <div className="land">
                                        3 Bedrooms Apartment
                                   </div>
                                   <div className="price">
                                    <TbCurrencyNaira className='naira'/> 30,000,000
                                   </div>
                                   <div className="deliverydeposit">
                                    <small>Delivery: Finished</small>
                                    <h5>Initial Deposit: N5,000,000</h5>
                                   </div>
                                </div>
                            </div>
                            <div className="offer">
                                <div className="icon">
                                    <BsFillHousesFill className='landicon' />
                                </div>
                                <div className="offerTitle">
                                   <div className="land">
                                        3 Bedrooms Apartment
                                   </div>
                                   <div className="price">
                                    <TbCurrencyNaira className='naira'/> 25,000,000
                                   </div>
                                   <div className="deliverydeposit">
                                    <small>Delivery: Carcass</small>
                                    <h5>Initial Deposit: N5,000,000</h5>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="infrastructure">
                <div className="infrasContent">
                    <div className="sectionsubTitle">Proposed Infrastructure</div>
                    <div className="amenities">
                        <div className="amenity">
                            <GiStreetLight className='amenityIcon' />
                            <h5>Street Light</h5>
                        </div>
                        <div className="amenity">
                            <MdFence className='amenityIcon'/>
                            <h5>Perimeter Fence</h5>
                        </div>
                        <div className="amenity">
                            <FaLightbulb  className='amenityIcon' />
                            <h5>24hrs Electricity Supply</h5>
                        </div>
                        <div className="amenity">
                            <FaRoad className='amenityIcon' />
                            <h5>Quality Road Network</h5>
                        </div>
                        <div className="amenity">
                            <BiSolidCctv className='amenityIcon' />
                            <h5>CCTV Security Surveillance</h5>
                        </div>
                        <div className="amenity">
                            <PiTreeEvergreenFill className='amenityIcon' />
                            <h5>Eco-friendly Environment</h5>
                        </div>
                        <div className="amenity">
                            <GiGate className='amenityIcon' />
                            <h5>Gated and Secured</h5>
                        </div>
                    </div>
                </div>
            </section>

            {/**development section starts here */}
            

            <section className="development">
                <div className="developmentContent">
                    <div className="sectionsubTitle">Ongoing Development</div>
                </div>
                <div className="devPics">
                    <div className="devpic">
                        <img src={devpic1} alt="naj court" />
                    </div>
                    <div className="devpic">
                        <img src={devpic2} alt="naj court" />
                    </div>
                    <div className="devpic">
                        <img src={devpic3} alt="naj court" />
                    </div>
                    <div className="devpic">
                        <img src={devpic4} alt="naj court" />
                    </div>
                </div>
            </section>
            {/**development section ends here */}

            {/**Downloads section starts here */}

            <section className="downloads">
                <div className='downloadContents'>
                    <div className="sectionsubTitle">Downloads</div>
                    <div className="download">
                        <button onClick={() => handleDownload('../../../asset/Najcourt1landFAQ.pdf')}>
                        Download FAQ
                        </button>
                        <button onClick={() => handleDownload('../../asset/naj1form.pdf')}>
                        Download  Subscription Form
                        </button>
                        <button onClick={() => handleDownload('../../asset/najcourtpaymentplan.pdf')}>
                        Download Payment Plan
                        </button>
                    </div>
                </div>
            </section>

        </div>
    </main>
  )
}

export default Najcourt1